import React from "react"

import { Image } from "@components/image"

import * as styles from "./memberships-logos.module.css"

const MembershipsLogos = () => {
  return (
    <>
      <h2 className={styles.heading}>Proud to work with our partners</h2>
      <div className={styles.container}>
        <Image
          src={`https://first-fence-ltd.mo.cloudinary.net/pages/ukas-iso-9001.jpg?tx=w_160,h_120`}
          alt="UKAS Accredited Logo"
          width="160"
          height="120"
          loading="lazy"
        />
        <Image
          src={`https://first-fence-ltd.mo.cloudinary.net/pages/footer/feefo-award.jpg?tx=w_120,h_120`}
          alt="Feefo Award"
          width="120"
          height="120"
          loading="lazy"
        />
        <Image
          src={`https://first-fence-ltd.mo.cloudinary.net/pages/footer/RISQS.jpg?tx=w_81,h_120`}
          alt="RISQS Logo"
          width="81"
          height="120"
          loading="lazy"
        />
        <Image
          src={`https://first-fence-ltd.mo.cloudinary.net/pages/footer/SBD_PPS.jpg?tx=w_185,h_120`}
          alt="SBD PPS Logo"
          width="185"
          height="120"
          loading="lazy"
        />
        <Image
          src={`https://first-fence-ltd.mo.cloudinary.net/pages/footer/RoSPA-Member.jpg?tx=w_160,h_78`}
          alt="RoSPA Member Logo"
          width="160"
          height="78"
          loading="lazy"
        />
        <Image
          src={`https://first-fence-ltd.mo.cloudinary.net/pages/footer/New-SSA-UK-logo.jpg`}
          alt="New SSA UK Logo"
          width="160"
          height="87"
          loading="lazy"
        />
        <Image
          src={`https://first-fence-ltd.mo.cloudinary.net/pages/footer/gate-safe-installer-logo.jpg`}
          alt="Gate Safe Installer Logo"
          width="160"
          height="108"
          loading="lazy"
        />
        <Image
          src={`https://first-fence-ltd.mo.cloudinary.net/pages/footer/DHF-logo-2020.jpg`}
          alt="DHF Logo"
          width="250"
          height="80"
          loading="lazy"
        />
        <Image
          src={`https://first-fence-ltd.mo.cloudinary.net/pages/footer/rail-cluster-scotland.jpg`}
          alt="Rail Cluster Scotland Logo"
          width="160"
          height="90"
          loading="lazy"
        />
      </div>
    </>
  )
}

export default MembershipsLogos
