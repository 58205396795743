import React from "react"

import { Link } from "gatsby"

import * as styles from "./important-links.module.css"

const ImportantLinks = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Important Links</div>
      <Link to="/about-first-fence" className={styles.text}>
        About Us
      </Link>
      <Link to="/contact-us" className={styles.text}>
        Contact Us &amp; Locations
      </Link>
      <Link to="/delivery-and-collection-service" className={styles.text}>
        Delivery &amp; Collection
      </Link>
      <Link to="/trade-account" className={styles.text}>
        Trade Account
      </Link>
      <Link to="/exports" className={styles.text}>
        Exports
      </Link>
      <Link to="/blog" className={styles.text}>
        Blog
      </Link>
      <Link to="/first-fence-jobs" className={styles.text}>
        Recruitment
      </Link>
      <Link to="/sustainability-plan" className={styles.text}>
        Sustainability
      </Link>
      <Link to="/testimonials" className={styles.text}>
        Testimonials
      </Link>
    </div>
  )
}

export default ImportantLinks
